export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/lotto/home/Home.vue'),
    // meta: {
    //   layout: 'full',
    // },
  },
  // {
  //   path: '/Profile',
  //   name: 'profile',
  //   component: () => import('@/views/lotto/profile/view.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/lotto/profile/profile.vue'),
  },
  {
    path: '/login2',
    name: 'login2',
    component: () => import('@/views/lotto/login/lv2.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/Profilebt',
    name: 'profile-bt',
    component: () => import('@/views/lotto/profile/viewbt.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/Deposit',
    name: 'deposit',
    component: () => import('@/views/lotto/deposit/deposit.vue'),
  },
  {
    path: '/Withdraw',
    name: 'withdraw',
    component: () => import('@/views/lotto/withdraw/withdraw.vue'),
  },
  {
    path: '/Status',
    name: 'status',
    component: () => import('@/views/lotto/status/status.vue'),
  },
  {
    path: '/Lottery',
    name: 'lottery',
    component: () => import('@/views/lotto/lottery/lottery.vue'),
  },
  // :type
  {
    path: '/Lottery/tang/:Type',
    name: 'tang',
    component: () => import('@/views/lotto/tanghuay/tanghuay.vue'),
    meta: {
      layout: 'full',
    },
  },
  // {
  //   path: '/Lottery/tang/',
  //   name: 'tang',
  //   component: () => import('@/views/lotto/tanghuay/tanghuayV3.vue'),
  // },
  {
    path: '/reward',
    name: 'reward',
    component: () => import('@/views/lotto/reward/reward.vue'),
  },
  {
    path: '/invite-friend',
    name: 'invite-friend',
    component: () => import('@/views/lotto/invite_friend/invite_friend.vue'),
  },
  {
    path: '/list-huay/:ListId',
    name: 'list-huay',
    component: () => import('@/views/lotto/list-huay/list.vue'),
  },
  {
    path: '/list-select',
    name: 'list-select',
    component: () => import('@/views/lotto/list-huay/list-select.vue'),
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/lotto/login/lv2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/lotto/register/view.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register_inv/:invcode/:ag',
    name: 'auth-register-inv',
    component: () => import('@/views/lotto/register/view.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // {
  //   path: '/register_noinv/:invcode/:noinv',
  //   name: 'auth-register-inv',
  //   component: () => import('@/views/lotto/register/view.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/register_ag/:invcode/:ag',
  //   name: 'auth-register-inv',
  //   component: () => import('@/views/lotto/register/view.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  {
    path: '/register_ag/:ag',
    name: 'auth-register-inv',
    component: () => import('@/views/lotto/register/view.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot',
    component: () => import('@/views/lotto/forgot-password/view.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
